a {
  color: #e20434;
  text-decoration: none;
}

.carousel .control-dots {
  bottom: 20px;
}

.carousel .control-dots .dot {
  width: 40px;
  height: 3px;
  border-radius: 0;
  box-shadow: none;
  transition: background 0.25s ease-in;
  opacity: 0.5;
  background: #90a4ae;
}

.carousel .control-dots .dot.selected {
  background: #e91e63;
  opacity: 1;
}

.slick-dots {
  width: calc(84.7% - 24px) !important;
  margin-left: 24px !important;
}

@media only screen and (max-width: 599px) {
  .carousel .control-dots .dot {
    width: 30px;
    height: 3px;
  }

  .slick-dots {
    width: calc(84.7% - 8px) !important;
    margin-left: 8px !important;
  }
}



/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-v30-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-v30-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}