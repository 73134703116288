.contentIcon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  padding: 15px;
  display: flex;
  background-clip: padding-box; /* !importanté */
  border: solid 3px transparent; /* !importanté */
  position: relative;
  background: #000;
}

.contentIcon:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -6px; /* !importanté */
  border-radius: inherit; /* !importanté */
  background: linear-gradient(to right, #e91e63, #ffa000);
}

.contentIcon svg {
  width: 100%;
  height: 100%;
}
